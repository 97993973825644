import router from '@/router'
export default {
    /**
     * 路由跳转
     * @param {*} params
     */
    navigate: (params) => {
        router.push(params)
    },
    /**
     * 返回上一页
     */
    goBack: () => {
        router.go(-1)
    }
}
