import storage from "@/utils/Storage"
const state = {
    lang: storage.get('lang'),
}

const mutations = {
    SET_LANG: (state, data) => {
        state.lang = data
        storage.set('lang', data)
    },
}

const actions = {
    set_lang({
        commit
    }, data) {
        commit('SET_LANG', data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}