import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import routes from "./routes"
const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL, //默认值: "/" , 应用的基路径。例如，如果整个单页应用服务在 /app/ 下，然后 base 就应该设为 "/app/" https://router.vuejs.org/zh/api/#base
    routes
})

import store from '@/store'


// import { requestAccounts } from "@/utils/metaMask";
import { connectWallet } from "@/utils/web3"

//路由守卫
router.beforeEach((to, from, next) => {
    // 进度条
    NProgress.start(); // 显示进度条
    NProgress.set(0.4); // 设置百分比
    NProgress.inc(); // 稍微增加

    const { walletAddress } = store.state.user

    if (!walletAddress) {
        connectWallet().then(res => {
            store.dispatch("user/set_wallet_address", res)
            next();
        }).catch(err => {
            console.log(err);
        })
        // requestAccounts().then((res) => {
        //     console.log("获取钱包地址==》", res);
        //     store.dispatch("user/set_wallet_address", res)
        //     next();
        //     // if (res) {
        //     //     store.dispatch("user/setLogin", {
        //     //         address: res,
        //     //         paddr: "",
        //     //         amount: 0,
        //     //     }).then((token) => {
        //     //         console.log("登录==》", token.access_token);
        //     //         store.dispatch("user/set_token", token.access_token)
        //     //         if (token.access_token) {
        //     //             next();
        //     //             console.log("已经进来了");
        //     //         }
        //     //     })
        //     // }
        // });
    } else {
        // console.log("已经登录过了=》进来了");
        next();
    }

    // if (to.matched.some((record) => record.meta.requireLogin)) {
    //     if (!to.meta.requireLogin) {
    //         next();
    //     } else {
    //         const { token } = store.state.user
    //         if (!token) {
    //             // next({ path: '/login', query: { redirect: to.fullPath } });
    //             next();
    //         } else {
    //             next();
    //         }
    //     }
    // } else {
    //     next();
    // }
});
router.afterEach(() => {
    NProgress.done(); //完成进度(进度条消失)
});

export default router