import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

import "@/components/vant"
import "@/icons";


import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

// import VConsole from 'vconsole';
// new VConsole();

//全局方法
import global from '@/utils/global.js'
Vue.prototype.$global = global


import i18n from '@/language'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
