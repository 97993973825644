import Vue from 'vue'
import 'vant/lib/index.css';

import {
    Button,
    Icon,
    Field,
    CellGroup,
    Swipe,
    SwipeItem,
    Rate,
    Collapse,
    CollapseItem,
    Popup,
    Toast,
    Picker,
    Image as VanImage,
    Popover,
    Tab, Tabs,
    Progress
} from 'vant';
Vue.use(Button);
Vue.use(Icon);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Rate);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(VanImage);
Vue.use(Popover);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Progress);