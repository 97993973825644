// import wallet from "./module/wallet";
// import woneQuota from "./module/wone-quota";
// import node from "./module/node";

export default [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
        keepAlive: false,
        name: "home",
        title: "首页"
    },
}, {
    path: '/mint',
    name: 'Mint',
    component: () => import('@/views/Mint'),
    meta: {
        keepAlive: false,
        name: "mint",
        title: "Mint"
    },
}]