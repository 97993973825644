/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Web3 from "web3"
import ABI from "./abi"
import BigNumber from "bignumber.js";

/**
 * 补齐64位，不够前面用0补齐
 * @param {*} num
 * @returns
 */
export const addPreZero = (num) => {
    var t = (num + '').length,
        s = '';
    for (var i = 0; i < 64 - t; i++) {
        s += '0';
    }
    return s + num;
}

// export const collection = "0x837d83b102Ba3e92Eb07bE17011A8A30aF332160"
export const collection = "0x2407c50Fad4374d9EDF441f6749593Aa49216161"

const getWeb3 = () => {
    // const HttpProvider = "http://127.0.0.1:8545"
    // return new Web3(new Web3.providers.HttpProvider(HttpProvider));
    return new Web3(window.ethereum);
}

/**
 * 连接钱包
 * @returns
 */
export const connectWallet = () => {
    return new Promise((resolve, reject) => {
        if (typeof window.ethereum === "undefined") {
            //没安装MetaMask钱包进行弹框提示
            // Message.warning("请安装MetaMask")
            reject('请安装MetaMask钱包')
        } else {
            //如果用户安装了MetaMask，你可以要求他们授权应用登录并获取其账号
            window.ethereum.enable().then((accounts) => {
                //这里返回用户钱包地址
                resolve(accounts[0]);
            }).catch((reason) => {
                //如果用户拒绝了登录请求
                if (reason.code === 4001) {
                    // 用户拒绝登录后执行语句；
                    reject('拒绝授权登录')
                } else {
                    // 本不该执行到这里，但是真到这里了，说明发生了意外
                    reject('发生意外,请重试')
                }
            });
        }
    })
}

/**
 * 获取 GasPrice
 * @returns
 */
export const getGasPrice = () => {
    return new Promise((resolve, reject) => {
        const web3 = getWeb3();
        const gasPrice = web3.eth.getGasPrice()
        resolve(gasPrice)
    });
}

/**
 * 获取 动态的 GasLimit 值
 * @param {*} from 当前地址
 * @param {*} to 合约地址
 * @param {*} amount 金额
 * @returns
 */
export const getGasLimit = (from, to, amount,invitersAddress) => {
    return new Promise((resolve, reject) => {
        const web3 = getWeb3();
        // const gasPrice = web3.eth.getGasPrice(); // estimate the gas price
        const sendType = "4ae0e178"
        const _amount = new BigNumber(amount).times(Math.pow(10, 18))
        const data = '0x' + sendType + addPreZero(invitersAddress.substr(2))
        const transactionObject = {
            from,//当前地址
            to,///合约地址
            // gasPrice,
            value: _amount.toNumber(),
            data,
        }
        web3.eth.estimateGas(transactionObject).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    });
}

/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = () => {
    return new Promise((resolve, reject) => {
        const web3 = getWeb3();
        const fromAddress = web3.eth.getAccounts()
        resolve(fromAddress)
    });
}

/**
 * 获取余额
 * @param {*} address
 */
export const getBalance = (address) => {
    return new Promise((resolve, reject) => {
        const web3 = getWeb3();
        web3.eth.getBalance(address, (err, res) => {
            if (!err) {
                // alert("ETH余额==" + res / Math.pow(10, 18));
                resolve((res / Math.pow(10, 18)).toFixed(4))
            }
        })
    });
}

/**
 * 发送
 */
export const send = ({ from, methodName, data = 0 }) => {
    return new Promise((resolve, reject) => {
        const web3 = getWeb3();
        const myContract = new web3.eth.Contract(ABI, collection);
        myContract.methods[methodName](data).send({
            from,
        }).on('receipt', (res) => {
            resolve(res)
        }).on('error', (error) => {
            reject(error)
        })
    });
}

/**
 * 读取
 */
export const call = ({ methodName, from, data = 0 }) => {
    return new Promise((resolve, reject) => {
        const web3 = getWeb3();
        const myContract = new web3.eth.Contract(ABI, collection);
        if (data) {
            myContract.methods[methodName](data).call({ from }, (err, res) => {
                resolve(res)
            })
        } else {
            myContract.methods[methodName]().call({ from }, (err, res) => {
                resolve(res)
            })
        }
    });
}

/**
 * 转账
 * @param {String} from 发件人钱包地址
 * @param {String} to 代币地址
 * @param {String} sendType a9059cbb=转账(默认) 095ea7b3=授权转账权限(approve)
 * sendType = 095ea7b3=授权转账权限 的时候 from=钱包地址 to=合约地址 amount/pow = Math.pow(10, 18)
 * @param {String} collection = 接收人地址
 * @param {Boolea} platform = 是否是平台转账
 */
export const sendTransaction = async ({ from, to = collection, amount, gasLimit, gasPrice, invitersAddress }) => {
    const web3 = getWeb3();
    // const myContract = new web3.eth.Contract(ABI, collection);
    const getGasPrice = await web3.eth.getGasPrice(); // estimate the gas price
    return new Promise((resolve, reject) => {
        // const gas = "0x186A0"
        // const gasPrice = "0x3B9ACA00"
        // const value = "0x00"
        const sendType = "4ae0e178"
        const _amount = new BigNumber(amount).times(Math.pow(10, 18))
        const data = '0x' + sendType + addPreZero(invitersAddress.substr(2))
        const params = [
            {
                from,//发件人钱包地址
                to,//代币地址
                gasPrice: getGasPrice,
                // gas, // （可选，默认值：90000）为交易执行提供的气体整数。它将返回未使用的气体。QUANTITY
                // gasPrice, // （可选，默认值：待定）用于每个付费气体的gas价格的整数。QUANTITY
                //非平台转账(代币) 释放 Start
                // value, // （可选）与此事务一起发送的值的整数。QUANTITY
                data,
                //非平台转账(代币) 释放 End
                value: '0x' + _amount.toString(16),
                gasLimit: Number(new BigNumber(gasLimit).times(1.5))
            },
        ]
        window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params
            })
            .then((txHash) => {
                resolve({ blockHash: txHash })
            })
            .catch((error) => {
                reject(error)
            });
    })
}

