<template>
  <div id="app" :class="{ 'linear-gradient-bg': !nogradient }">
    <!-- <menu-nav @handle-open-menu="$refs.menu.open()" />
    <menu-view ref="menu" /> -->
    <router-view />
  </div>
</template>

<script>
// import menuNav from "@/components/nav-menu/nav";
// import menuView from "@/components/nav-menu/menu";
export default {
  // components: { menuNav, menuView },
  // watch: {
  //   "$route.meta": {
  //     handler(val, oldval) {
  //       this.nogradient = val.gradient === false;
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      nogradient: true,
    };
  },
  name: "App",
};
</script>

<style>
@import "@/assets/styles/common.scss";
</style>
