import Vue from "vue";
import store from '@/store'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);   // 全局注册国际化包


import zh from "./zh"
import ko from "./ko"


// 自动设置语言
export const allLang = ['zh', 'ko']
const navLang = navigator.language;

const filterLang = (lang) => {
    const _f = allLang.filter(item => item.indexOf(lang) != -1)
    if (_f.length == 0) {
        return "zh"
    } else {
        return _f[0]
    }
}
const lang = store.state.system.lang || filterLang(navLang) || 'zh';
Vue.config.lang = lang;

store.dispatch("system/set_lang", lang);

// 准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: lang,
    messages: {
        zh,
        ko,
    }
});

export default i18n