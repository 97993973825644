/**
 * 本地缓存
 */
const storage = {
    set(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    },

    get(key) {
        try {
            const value = sessionStorage.getItem(key);
            return value ? JSON.parse(value) : null;
        } catch (err) {
            return null;
        }
    },

    remove(key) {
        sessionStorage.removeItem(key);
    },

    clear() {
        sessionStorage.clear();
    }
};
export default storage