/* eslint-disable no-unused-vars */
import storage from "@/utils/Storage"
const state = {
    walletAddress: storage.get('walletAddress') || "",
    token: storage.get('token') || "",
}

import { call, getBalance } from "@/utils/web3";

const mutations = {
    //钱包地址设置
    SET_WALLET_ADDRESS: (state, data) => {
        state.walletAddress = data
        storage.set('walletAddress', data);
    },
    //设置token
    SET_TOKEN: (state, data) => {
        state.token = data
        storage.set('token', data);
    },

    CLEAR: (state, data) => {
        state.walletAddress = ''
        state.token = ''
        storage.clear()
    },
}

const actions = {
    //钱包地址设置
    set_wallet_address({
        commit
    }, data) {
        commit('SET_WALLET_ADDRESS', data)
    },

    //token设置
    set_token({
        commit
    }, data) {
        commit('SET_TOKEN', data)
    },

    //删除所有缓存
    clear({
        commit
    }) {
        commit('CLEAR', "")
    },

    /**
     * 获取是否绑定
     * @param {*} param0
     * @returns
     */
    async getBinding({ commit, state }) {
        return new Promise((resolve, reject) => {
            call({
                methodName: "getsuperior",
                from: state.walletAddress,
            }).then((res) => {
                console.log(res);
                if (Number(res) == 0) {
                    resolve("")
                }
                resolve(res)
            }).catch(err => {
                console.log(err);
                reject(err)
            });
        })
    },
    /**
     * 获取总铸造代币数量
     * @param {*} param0
     * @returns
     */
    async getTotaltoken({ commit, state }) {
        return new Promise((resolve, reject) => {
            call({
                methodName: "getTotaltoken",
                from: state.walletAddress,
            }).then((res) => {
                if (Number(res) == 0) {
                    resolve(0)
                } else {
                    resolve(res / Math.pow(10, 8)+545000)
                }
            }).catch(err => {
                reject(err)
            });
        })
    },
    /**
     * 获取余额
     * @param {*} param0
     * @returns
     */
    async getBalance({ commit, state }) {
        return new Promise((resolve, reject) => {
            getBalance(state.walletAddress).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
